.blockLogo {
  max-width: 454px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  margin-right: 62px;
}
.blockLogo img {
  width: 100%;
  margin-bottom: 76px;
}
.blockLogo span {
  font-size: 34.864px;
}
.root {
  display: flex;
  margin: 50px auto;
  justify-content: center;
  align-items: center;
}
.root h2 {
  font-size: 40.234px;
  margin-bottom: 40px;
  font-weight: 400;
}
.register {
  // TODO: Сделать что-то с адаптивной высотой
  width: 599px;
  height: 820px;
  padding: 72px 54px;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 3px 7px 30px 0px rgba(0, 0, 0, 0.1);
  // align-items: flex-start;
}
.register_wrapper {
  width: 100%;
}
.register_wrapper form {
  display: flex;
  flex-direction: column;
}

.email,
.first_name,
.last_name,
.password {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.email input,
.first_name input,
.last_name input,
.password input {
  width: 100%;
  border: 0;
  outline: none;
  height: 30px;
  padding: 20px 10px 25px 3px;
  font-size: 24px;
}

input::placeholder {
  color: #000;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #000;
}

input::-ms-input-placeholder {
  color: #000;
}

.btn {
  margin: 14px 0 90px 0;
}
.btn button {
  width: 100%;
  padding: 27px;
  color: #fff;
  border: 0;
  cursor: pointer;
  border-radius: 50px;
  background: #1AB394;
  font-size: 28.299px;
  font-weight: 500;
  transition: background-color 0.5s;

  &:active {
    transform: translateY(1px);
  }
  &:hover {
    background: #0AA384;
  }

  &:disabled {
    background-color: #888888;
    cursor: no-drop;
    &:active {
      transform: translateY(0px);
    }
  }
}

.error {
  font-size: 16px;
  color: rgb(248, 28, 28);
  font-weight: 600;
  height: 39px;

  p {
    text-align: left;
    line-height: 30px;
  }
}
.success {
  font-size: 16px;
  color: rgb(71, 194, 71);
  font-weight: 600;
}
@media (max-width: 1100px) {
  .blockLogo {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .blockLogo img {
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
  }

  .blockLogo span {
    font-size: 32px;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 20px;
    width:100%;
  }
  .root {
    flex-direction: column; /* Элементы в корневом контейнере теперь расположены вертикально */
    margin: 20px auto; /* Уменьшаем внешний отступ */
    padding: 0 10px; /* Добавляем небольшой внутренний отступ */
    height: auto;
  }

  .root h2 {
    font-size: 24px; /* Уменьшаем размер шрифта заголовка */
    margin-bottom: 20px;
  }

  .register {
    width: 100%; /* Растягиваем блок регистрации на всю доступную ширину */
    height: auto; /* Высота теперь автоматическая */
    padding: 30px 20px; /* Уменьшаем внутренние отступы */
    border-radius: 4px; /* Уменьшаем радиус скругления */
  }

  .email input,
  .first_name input,
  .last_name input,
  .password input {
    padding: 15px 10px; /* Меньше отступы внутри полей ввода */
    font-size: 14px; /* Меньший размер шрифта в полях ввода */
  }

  .btn button {
    padding: 15px; /* Уменьшаем внутренний отступ кнопки */
    font-size: 20px; /* Уменьшаем размер шрифта кнопки */
  }
  .backLogin{
    display: flex;
    justify-content: end;
  }
  .backLogin > div {
    display: flex;
  }
  .backLogin a{
    background: none;
    border: 0;
    cursor: pointer;
    color: #000;
    font-weight: 700;
    font-size: 18px;
    justify-content: end;
    margin-left: auto;
  }
  .backLogin span {
    margin-right: 5px;
    font-size: 18px;
    text-align: left;
  }
}