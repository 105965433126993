.email,
.password {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.email {
  .error {
    font-size: 16px;
    color: rgb(248, 28, 28);
    font-weight: 600;
    height: 39px;

    p {
      text-align: left;
      line-height: 30px;
    }
  }
}
.password {
  .error {
    font-size: 16px;
    color: rgb(248, 28, 28);
    font-weight: 600;
    height: 25px;

    p {
      text-align: left;
      line-height: 30px;
    }
  }
}
.email input,
.password input {
  width: 100%;
  border: 0;
  outline: none;
  height: 30px;
  padding: 20px 10px 25px 3px;
  font-size: 24px;
}
