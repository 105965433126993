.resetLogo{
    max-width: 454px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    transform: scale(2);
}
.passwordConfirmContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;

.resetPassword {
    width: 353px;
    padding: 10px 20px;
    background-color: #1AB394;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0AA384;
    }
}

.resetH2 {
    margin-top: 30px;
    margin-bottom: 20px;
}  
    .formgroup {
    width: 100%;
    margin-bottom: 15px;

    label {
        display: block;
        margin-bottom: 5px;
        text-align: center;
    }

    input[type="email"],
    .email {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .error {
        color: red;
        text-align: center;
    }
}
    
.message {
    color: green;
    text-align: center;
    margin-top: 15px;
    }
}
  