.articles {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
  transition: all 0.5s;
  padding: 10px;
  border-radius: 7px;
}
.articles:hover {
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
}

.wrapper_img {
  max-width: 229px;
  width: 100%;
}
.wrapper_img img {
  border-radius: 7px;
  width: 100%;
}
.wrapper_right_block {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 38px;
  text-align: left;
  color: #000;
}
.headline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.headline h4 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}
.headline span {
  font-weight: 400;
  white-space: nowrap;
  padding: 0 5px;
}
.text {
  color: #929292;
  font-size: 14px;
  line-height: 141.9%;
  font-weight: 400;
}
.date p {
  color: #929292;
  font-size: 14px;
  font-weight: 400;
}
.date span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  margin-left: 7px;
}
.date {
  align-self: flex-start;
  margin-top: auto;
}
.date span:global.active {
  color: #89b126;
}

@media (max-width: 1100px) {
  .articles {
    flex-direction: row;
    padding: 10px;
    padding-left: 10px;
    }
  .wrapper_img {
    max-width: 150px;
    padding-right: 20px;
  }
  .wrapper_right_block {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    text-align: left;
    color: #000;
  }
  .headline {
    flex-direction: column;
    align-items: flex-start;
  }
  .headline h4 {
    font-size: 22px;
  }
  .text {
    font-size: 0px;
  }
  .date p {
    font-size: 16px;
  }
  .date span {
    font-size: 14px;
  }
  .date {
    align-self: flex-start;
    margin-top: auto;
  }
  .date span:global.active {
    color: #89b126;
  }
}
