.artsH2 {
  font-size: 42px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 48px;
}

.container {
  display: flex;
  margin: 20px auto;
  max-width: 1100px;
  padding-left: 20px; /* Уменьшите padding */
  padding-right: 20px; /* Добавьте padding справа */
  margin-top: 76px;
  width: 100%;
  box-sizing: border-box;
}

.categories {
  flex: 1;
  margin-right: 20px;
  margin: 10px;
  margin-left: 10px;
}

.articles {
  flex: 3;
}

.active {
  font-weight: bold;
}

.ul {
  list-style: none;
  padding: 0;
  padding-top: 80px;
  width: 100%;
}

.li {
  cursor: pointer;
  margin: 10px 0;
  margin-bottom: 5px;
  margin-right: 20px;
  color: #000;
  transition: color 0.5s ease;
  position: relative;
  display: block;
  width: fit-content;
}

.li:hover {
  opacity: 0.5;
}

.li::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 1px;
  background-color: #000;
  transform: scaleX(0); 
  transform-origin: left;
  transition: transform 0.3s ease;
}

.active::before {
  transform: scaleX(1);
}
.addButton {
  background-color: #1AB394;
  color: white;
  border: none;
  padding: 9px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s; 
  float: right;
  width: 200px;
  // margin-left: 10px;
  margin-top: -90px;
}
.addButton:active {
  background-color: #0AA384;
  box-shadow: 0 5px #666;
  transform: translateY(4px); 
}
.addButton:hover {
  background-color: #0AA384;
}
.addButton:focus {
  outline: none;             
  border-color: #0AA384;     
  box-shadow: 0 0 10px #0AA384;
}

@media (max-width: 1100px) {
  .addButton {
    display: none;
  }
  .articles {
    flex: 1;
  }
  .artsH2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  }

  .container {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
    overflow-x: hidden;
    flex-direction: column;
  }

  .categories {
    margin: 0 auto 10px auto;
  }
  .li {
    margin: 5px 0;
    font-size: 14px;
  }

  .ul {
    padding-top: 10px;
  }
}
