.articleBlock {
  max-width: 1200px;
  margin: 20px auto;
  margin-top: 20px;
  padding-top: 20px;
  padding: 20px;
  padding-bottom: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  word-wrap: break-word;
  text-align: left;

  .editButton {
    background-color: #1AB394;
    color: white;
    border: none;
    padding: 9px 15px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s; 
    float: right;
    // margin: 0 30px;
  }
  .editButton:hover {
    background-color: #0AA384;
  }
  .editButton:active {
    background-color: #0AA384;
    box-shadow: 0 5px #666;
    transform: translateY(4px); 
  }
  .editButton:focus {
    outline: none;             
    border-color: #0AA384;     
    box-shadow: 0 0 10px #0AA384;
  }
  
  .deleteButton {
    background-color: #1AB394;
    color: white;
    border: none;
    padding: 9px 15px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s; 
    float: right;
    margin: 0 30px;
  }
  .deleteButton:hover {
    background-color: #0AA384; 
  }
  .deleteButton:active {
    background-color: #0AA384; 
    box-shadow: 0 5px #666;    
    transform: translateY(4px);
  }
  .deleteButton:focus {
    outline: none;             
    border-color: #0AA384;     
    box-shadow: 0 0 10px #0AA384; 
  }

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 0.5em;
    text-align: center;
  }

  p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #666;
    margin-top: 0;
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .content {
    margin-top: 20px;
    margin-bottom: 20px;

    p, li, h2, h3 {
      color: #444;
    }

    a {
      color: #0056b3;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .category, .date {
    font-style: italic;
    color: #888;
  }
}

@media (max-width: 1100px) {
  .articleBlock {
    margin: 20px auto;
    margin-top: 20px;
    padding-top: 20px;
    padding: 20px;
    padding-bottom: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    word-wrap: break-word;
    text-align: left;
    font-size: 0.9em;
  }
  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }

  .content {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
