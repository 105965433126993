// BackToTopButton.module.scss
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.back-to-top-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-decoration: none;
    background: #1AB394; /* Bootstrap primary color */
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.back-to-top-button:hover {
    background: #0AA384; /* Darker shade of primary color */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-4px);
}

.back-to-top-button:active {
    background: #0AA384; /* Even darker shade */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
}
