* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Comfortaa', cursive;
}

h2 {
  font-size: 42px; /* Большой размер шрифта для десктопов */
  font-weight: 500;
  text-align: left;
  margin-bottom: 48px;
}

.editorWrapper {
  width: 100%;
  .editor {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.footer {
  background-color: #f8f9fa;
  text-align: center;
  padding: 1rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.codex-editor__redactor {
  padding-bottom: 50px !important;
}

#editorjs-viewer {
  width: 100%;
  padding: 0;
  margin: 0;
  // padding-bottom: -300px;
  .ce-block {
    max-width: 100%;
  }
  .ce-block__content {
    max-width: 100%;
  }
  .ce-toolbar__content {
    max-width: 100%;
  }
}
.App {
  text-align: justify;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.contentWrapper {
  flex: 1;
}
.Toastify__toast {
  text-align:left;
}

a {
  text-decoration: none;
}

.ql-editor {
  min-height: 200px !important;
  background-color: #ffffff;
}
.ql-toolbar {
  padding-top: 100px;

}

.sv-question {
  max-width: 100%;
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 1100px) {
  h2 {
    font-size: 28px; /* Меньший размер шрифта для мобильных */
    margin-bottom: 20px; /* Меньший нижний отступ */
    text-align: center;
    padding-top: 20px;
  }

  .App-logo {
    height: 20vmin; /* Меньший размер логотипа на мобильных устройствах */
  }

  .container {
    padding: 0 10px; /* Добавление небольших отступов по бокам */
  }

  .App-header {
    font-size: calc(9px + 3vmin); /* Адаптивный размер шрифта для заголовков */
    padding: 20px 0; /* Добавление вертикальных отступов */
  }

  .ql-editor {
    min-height: 150px; /* Уменьшенная высота редактора */
  }
}
