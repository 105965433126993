
.formContainer {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
    .ArticleUpdateH2 {
      text-align: center;
      color: #333;
      font-size: 24px;
      margin-bottom: 30px;
      padding-top: 30px;
    }
  
    .formGroup {
      margin-bottom: 20px;
  
      .formLabel {
        display: block;
        margin-bottom: 8px;
        font-size: 16px;
        color: #333;
      }
  
      .input, .selectCategory {
        width: 100%;
        padding: 8px 12px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
  
        &:focus {
          border-color: #333;
          outline: none;
          box-shadow: 0 0 5px rgba(0, 86, 179, 0.25);
        }
      }
  
      .quillEditor {
        .ql-container {
          height: 200px;
          background-color: #333;
        }
      }
    }
  
    .submitButton {
      display: block;
      width: 100%;
      padding: 10px;
      font-size: 18px;
      text-align: center;
      color: white;
      background-color: #1AB394;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0AA384;
      }
    }
  }
  