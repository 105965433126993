.forgotcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 800px;
  // width: 100vw;

  .resetLogo {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    // transform: scale(2);
  }

  .resetH2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .formgroup {
      width: 100%;
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
        text-align: center;
      }

      input[type="email"],
      .email {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      .error {
        color: red;
        text-align: center;
      }
    }

    .resetPassword {
      width: 100%;
      padding: 10px 20px;
      background-color: #1AB394;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0AA384;
      }
    }
  }

  .message {
    color: green;
    text-align: center;
    margin-top: 15px;
  }

  @media (max-width: 1100px) {
    .resetLogo {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
      // transform: scale(2);
    }
  }
}